import * as yup from 'yup';

yup.setLocale({
  mixed: { required: 'yup.required_field', notType: 'yup.wrong_type' }
});

export const validateSchema = {
  first_name: yup
    .string()
    .required()
    .matches(/^[A-Za-z. ]+$/, 'yup.only_cahracters')
    .min(2)
    .max(40),
  last_name: yup
    .string()
    .required()
    .matches(/^[A-Za-z. ]+$/, 'yup.only_cahracters')
    .min(2)
    .max(40),
  name: yup.string().min(2).max(40),
  email: yup.string().email().required().max(50),
  confirmEmail: yup
    .string()
    .oneOf([yup.ref('email'), null], 'yup.emails_match')
    .required()
    .max(50),
  loginEmail: yup.string().required().max(50),
  loginPassword: yup.string().required().max(20),
  oldPassword: yup.string().required().max(40),
  password: yup
    .string()
    .required()
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]/, 'yup.password_pattern')
    .min(8, 'password_min')
    .max(20, 'password_max'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'yup.passwords_match')
    .required()
    .max(40),
  missingRequest: yup.string().required().max(4000),
  question_optional: yup.string().optional().max(4000),
  question: yup.string().required().max(4000),
  recaptchatoken: yup.string().notOneOf(['no value'], 'yup.captcha'),
  complaint: yup.string().required().max(4000),
  speciality: yup.string().required(),
  mobile: yup.string().required().max(30),
  date_of_birth: yup.date().required(),
  country: yup.string().required().max(40),
  address: yup.string().required().max(100),
  gender: yup.string().required().max(10),
  blood_type: yup.string().required().max(12),
  country_id: yup.string().required().max(10),
  weight: yup.number().required().max(500),
  height: yup.number().required().max(300),
  checkBox_required: yup.boolean().oneOf([true], 'yup.required_field'),
  checkBox_notRequired: yup.boolean()
};
