import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Popup from 'reactjs-popup';

function Loading() {
  let loading = useSelector((state) => state.mainFun.loading);
  const { t } = useTranslation();
  return (
    <Popup open={loading.length > 0 ? true : false} key="loading" closeOnDocumentClick={false}>
      <div
        className="d-flex flex-column align-items-center green-txt animate__animated animate__zoomIn"
        dir="ltr"
      >
        {import.meta.env.VITE_LOADER == 'plus' ? (
          <div className="loader-plus">
            <div className="loader-right" />
            <div className="loader-top" />
            <div className="loader-left" />
          </div>
        ) : (
          <>
            <div className="spinner-border mb-3" role="status">
              <span className="sr-only" />
            </div>
            <span>{t('loading')}...</span>
          </>
        )}
      </div>
    </Popup>
  );
}

export default Loading;
