import { useEffect, useLayoutEffect, useState } from 'react';
import moon from '../assets/images/svg/moon.svg';
import sun from '../assets/images/svg/sun.svg';

const ThemeToggle = ({ toggler }) => {
  const [theme, setTheme] = useState();
  const [animate, setAnimate] = useState();

  const isDarkMode = () => window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

  useLayoutEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    const newTheme =
      (['dark', 'light'].includes(storedTheme) && storedTheme) || (isDarkMode() ? 'dark' : 'light');
    toggleTheme(newTheme);
  }, []);

  const toggleTheme = (newTheme) => {
    document.body.dataset.theme = newTheme;
    document.body.setAttribute('data-bs-theme', newTheme);
    localStorage.setItem('theme', newTheme);
    setTheme(newTheme);
    setAnimate(true);
  };

  return !toggler ? (
    <div
      className={'pointer' + (animate ? ' animate__animated animate__flipInY' : '')}
      onAnimationEnd={() => setAnimate(false)}
    >
      {theme === 'dark' ? (
        <div className="bg-warning rounded-circle theme-toggle" onClick={() => toggleTheme('light')}>
          <img src={sun} width={25} alt="" />
        </div>
      ) : (
        <div className="bg-black p-1 rounded-circle theme-toggle" onClick={() => toggleTheme('dark')}>
          <img className="icon-color" src={moon} width={25} alt="" />
        </div>
      )}
    </div>
  ) : (
    <div>
      <li className="mode d-flex justify-content-between px-3 rounded-3 list">
        {theme === 'dark' ? <img width={25} src={sun} /> : <img width={25} src={moon} />}
        <span className="mode-text text">{theme == 'light' ? 'Dark' : 'Light'} mode</span>

        <div className="toggle-switch" onClick={() => toggleTheme(theme == 'light' ? 'dark' : 'light')}>
          <span className="switch"></span>
        </div>
      </li>
    </div>
  );
};
export default ThemeToggle;
