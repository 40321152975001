import React, { useEffect, useRef, useState } from 'react';
import Checkbox from './Checkbox';
import profile from '../assets/images/svg/profile.svg';
import ReCAPTCHA from 'react-google-recaptcha';

const ReCaptcha = ({ register, errors, captcha }) => {
  const [captchaValue, setCaptchaValue] = useState('no value');
  const captchaRef = useRef(null);

  useEffect(() => {
    let input = captchaRef?.current?.querySelector('input');
    if (captchaValue != 'no value') {
      input?.click();
    } else if (input?.checked && captchaValue == 'no value') {
      input?.click();
    }
  }, [captchaValue]);

  return (
    <div className="recaptcha_container">
      <ReCAPTCHA
        sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
        onChange={(token) => {
          setCaptchaValue(token);
        }}
        onExpired={() => setCaptchaValue('no value')}
      />
      <div ref={captchaRef}>
        <input type="checkbox" {...register} value={captchaValue} className="d-none" />
      </div>

      {/* <div className="recaptcha">
        <div className="recaptcha_left">
          <Checkbox name="recaptcha" register={register} addClass errors={errors} />
          <h3 className="fs-5">I'm not a robot</h3>
        </div>
        <div className="bg-green rounded-circle p-1">
          <img src={profile} />
        </div>
      </div>
       */}
      {errors && <p className="text-capitalize err-msg mt-2 fs-8">{errors}</p>}
    </div>
  );
};

export default ReCaptcha;
