import maintenance from '../../assets/images/u_m.png';

function UnderMaintenance() {
  return (
    <div className='under-maintenance'>
      <img src={maintenance} />
    </div>
  );
}

export default UnderMaintenance;
