import React from 'react';
import MainBtn from '../Button.jsx';
import { awsRum } from '../../aws.rum.config.js';
import ErrorTemplate from './ErrorTemplate.jsx';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error);
    awsRum?.recordError(errorInfo);
  }

  render() {
    console.log(this.state);
    if (this.state.hasError) return <ErrorTemplate />;
    return this.props.children;
  }
}

export default ErrorBoundary;
