import { configureStore } from '@reduxjs/toolkit';
import caseDetailsReducer from './features/case_details.js';
import mainFunReducer from './features/main_functions.js';

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
  reducer: {
    caseDetails: caseDetailsReducer, //case details in case view
    mainFun: mainFunReducer //main functions like: loading, alert, role
  }
});

export default store;
