import React, { useRef, useState } from 'react';
import right from '../assets/images/svg/right.svg';

const Checkbox = ({ title, register, errors }) => {
  const [checked, setChecked] = useState(false);
  const checkRef = useRef();

  return (
    <>
      <div className="d-flex align-items-center">
        <div
          className={`${checked ? 'bg-dark-green' : 'bg-grey'} ${
            errors && 'err-active border'
          } mainCheckbox me-3 rounded-3 pointer`}
          ref={checkRef}
          onClick={() => {
            setChecked(!checked);
            checkRef?.current?.querySelector('input')?.click();
          }}
          test-id={'checkbox-' + register?.name}
        >
          <input type="checkbox" className="d-none" {...register} />
          <img className={`${!checked ? 'invisible' : ''} right-ico`} src={right} />
        </div>
        <p className="fs-8">{title}</p>
      </div>
      {errors && <p className="text-capitalize err-msg mt-2 fs-8">{errors}</p>}
    </>
  );
};

export default Checkbox;
