import { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactSelect, { components } from 'react-select';
import makeAnimated from 'react-select/animated';

const Select = ({
  title,
  register,
  errors,
  onBlur,
  onChange,
  name,
  data,
  value,
  required,
  setValue,
  isMulti,
  control,
  isClearable
}) => {
  useEffect(() => {
    if (setValue) {
      console.log(value);
      setValue(register?.name || name, value || '');
    }
  }, [value, setValue]);

  const { control: reactSelectControl } = useForm();

  const ref = useRef();
  const { t } = useTranslation();

  const animatedComponents = makeAnimated();
  return (
    <div>
      {title && <p className="mb-2 text-capitalize fw-medium">{title}</p>}
      <Controller
        name={name || ''}
        control={control || reactSelectControl}
        defaultValue={value}
        render={({ field }) => {
          return (
            <ReactSelect
              {...field}
              options={data}
              required={required}
              isMulti={isMulti}
              isSearchable
              components={{
                Option: (props) => {
                  const testId = props.options.findIndex((option) => option.value === props.data.value);
                  return (
                    <components.Option {...props}>
                      <span key={props.innerProps.key} test-id={name + '-option-' + testId}>
                        {props.data.label}
                      </span>
                    </components.Option>
                  );
                }
              }}
              placeholder={t('select') + '...'}
              menuPortalTarget={document.body}
              isClearable={isClearable}
              autoFocus={false}
              menuPlacement="auto"
              value={field.value && data?.find((e) => e.value == field.value)}
              ref={(e) => {
                field?.ref(e);
                ref.current = e;
              }}
              onChange={(e) => {
                field?.onChange(e?.value);
                onChange && onChange(e);
              }}
              onBlur={(e) => {
                field?.onBlur(e?.value);
                onBlur && onBlur(e);
              }}
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderRadius: '',
                  borderColor: '',
                  borderWidth: ''
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: ''
                }),
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999
                })
              }}
              classNames={{
                control: (state) =>
                  `mainInput p-0 ${state.isFocused ? 'select-focused' : ''} ${errors && 'err-active'} ${
                    title || 'clearBorders'
                  }`,
                placeholder: () => 'placeholder-txt',
                menu: () => 'select-menu',
                input: () => 'bg-transparent',
                option: (state) => (state.isFocused ? 'bg-primary secondary-txt' : '')
              }}
            />
          );
        }}
      />
      <button type="button" className="d-none" id={name} onClick={() => ref.current.onMenuOpen()} />
      {errors && <p className="text-capitalize err-msg mt-2 fs-8">{errors}</p>}
    </div>
  );
};

export default Select;
