import { useEffect, useState } from 'react';
import closeIcon from '../assets/images/svg/close.svg';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setAlert } from '../features/main_functions.js';

function Alert() {
  const alertDetails = useSelector((state) => state.mainFun.alert);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (alertDetails) {
      setShow(true);
      const timer = setTimeout(() => {
        setShow(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [alertDetails]);

  useEffect(() => {
    if (!show) dispatch(setAlert());
  }, [show]);

  return (
    show && (
      <div
        className={`alert alert-${alertDetails?.type} alert-box animate__animated animate__slideInRight `}
        role="alert"
      >
        {alertDetails?.txt}
        <button type="button" className="ms-3 btn-close" onClick={() => setShow(false)} />
      </div>
    )
  );
}

export default Alert;
