import React, { useEffect } from 'react';
import Popup from 'reactjs-popup';
import closeIcon from '../assets/images/svg/close.svg';
import MainBtn from './Button.jsx';

function PopupContainer({
  children,
  trigger,
  open,
  title,
  closeBtn = true,
  btns,
  handleSubmit,
  onClose,
  onMount,
  disabled,
  type
}) {
  let formBody = (e) => {
    let formData = new FormData(e.target);
    let data = {};
    for (const pair of formData.entries()) {
      data[pair[0]] = pair[1];
    }
    return data;
  };

  return (
    <Popup
      trigger={() =>
        trigger ? (
          <div>
            <MainBtn {...trigger} />
          </div>
        ) : (
          <></>
        )
      }
      open={Boolean(open)}
      modal
      nested
      onOpen={onMount}
      closeOnDocumentClick={closeBtn}
      closeOnEscape={false}
      onClose={onClose}
      disabled={disabled}
      key="popup"
    >
      {(close) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit && handleSubmit(formBody(e), close);
          }}
          className={`popup-msg-style d-flex flex-column animate__animated animate__zoomIn ${
            type == 'warning' ? 'bg-warning' : ''
          }`}
        >
          <div
            className={`${title && 'border-bottom border-2 mb-2 pb-2'} d-flex flex-row align-items-center`}
          >
            {title && <p className="fs-7 fw-semibold text-capitalize me-5">{title || false}</p>}
            {closeBtn && <img src={closeIcon} onClick={close} className="ms-auto pointer" />}
          </div>
          <div className="overflow-x-hidden overflow-y-auto d-flex flex-column gap-3 my-2">{children}</div>
          {btns && (
            <div className="d-flex gap-2 mt-2">
              {btns.map((e, i) => (
                <div className="flex-fill" key={e.txt + i}>
                  <MainBtn
                    txt={e.txt}
                    style={e.style}
                    type={e.type}
                    action={e.action ? (action) => e.action(action, close) : null}
                    id={e.id}
                  />
                </div>
              ))}
            </div>
          )}
        </form>
      )}
    </Popup>
  );
}
export default PopupContainer;
