import filter from '../assets/images/svg/filter.svg';
import MainBtn from './Button.jsx';
import { useNavigate } from 'react-router-dom';
import DropDown from './Dropdown.jsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SearchInput from './SearchInput.jsx';
import moment from 'moment';

function Search({ setSearchedData, caseInProgress, caseListData }) {
  console.log(caseListData);
  const { role } = useSelector((state) => state.mainFun.user);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const searchData = (value) => {
    const lowerCaseValue = value.toLowerCase();
    const newData = caseListData.filter(
      (e) =>
        e[0].label.toLowerCase().includes(lowerCaseValue) ||
        e[1].label.toLowerCase().includes(lowerCaseValue) ||
        e[2].label.toLowerCase().includes(lowerCaseValue)
    );
    setSearchedData(newData);
  };
  const filterCases = (value, type) => {
    let newData = [];
    console.log(value);
    if (type == 'date') {
      newData = caseListData.filter((e) => e[3].label == moment(value).format('DD/MM/YYYY'));
    } else if (type == 'state') {
      newData = caseListData.filter((e) => e[5].label.toLowerCase().includes(value.toLowerCase()));
    } else {
      newData = caseListData;
    }
    setSearchedData(newData);
  };

  let dropOptions = [
    { txt: t('case_list.drop.date'), action: (e) => filterCases(e.target.value, 'date'), type: 'date' },
    {
      txt: t('case_list.drop.state'),
      options: [
        { title: t('status.sent_to_expert'), value: 'sent to expert' },
        { title: t('status.accepted'), value: 'accepted' },
        { title: t('status.follow_up'), value: 'follow up' },
        { title: t('status.closed'), value: 'closed' }
      ],
      action: (e) => filterCases(e.target.value, 'state')
    },
    { txt: t('case_list.drop.reset'), action: (e) => filterCases('', 'reset'), type: 'reset' }
  ];
  return (
    <div className="d-flex justify-content-between flex-row">
      <p className="text-capitalize fs-4 w-25">{t('cases')}</p>
      <div className="d-flex flex-row w-75 justify-content-end">
        {role == 'patient' ? (
          <div className="input-group">
            <MainBtn
              txt={caseInProgress ? t('case_list.continue_case') : t('case_list.new_case')}
              style="green-btn w-auto ms-auto"
              action={() => navigate('../newcase')}
            />
          </div>
        ) : (
          <>
            <SearchInput onChange={searchData} />
            <DropDown
              className="filter-btn p-1 ms-2 d-flex gap-2 align-items-center primary-txt"
              img={filter}
              autoClose="outside"
              txt={t('case_list.filter')}
              option={dropOptions}
              type="filter"
            />
          </>
        )}
      </div>
    </div>
  );
}

export default Search;
