import Input from './Input.jsx';
import MainBtn from './Button.jsx';
import { Link } from 'react-router-dom';
import Validation from '../validate/validate.js';
import ReCaptcha from './ReCaptcha.jsx';
import Checkbox from './Checkbox.jsx';
import Select from './Select.jsx';
import TextArea from './TextArea.jsx';
import InputWithDropdown from './InputWithDropdown.jsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

function Form({
  smallForm,
  title,
  inputArr,
  btnArr,
  selectArr,
  textAreaArr,
  checkboxArr,
  type,
  captcha,
  onSubmit,
  defaultValues,
  apiError,
  customElement,
  inputWithDropdownArr,
  customField,
  setFormValidate
}) {
  const { role } = useSelector((state) => state.mainFun.user);
  const { t } = useTranslation();

  let { handleSubmit, register, errors, control, setValue, setError } = Validation({
    type,
    defaultValues,
    customField,
    shouldUnregister: true
  });

  const formData = (data) => {
    console.log(data);
  };

  useEffect(() => {
    if (apiError) {
      setError(apiError.name, { type: 'focus', message: apiError.message }, { shouldFocus: true });
    }
  }, [apiError]);

  useEffect(() => {
    setFormValidate && setFormValidate({ control, setValue, setError });
  }, [control, setFormValidate]);

  return (
    <form
      className="d-flex justify-content-center"
      onSubmit={handleSubmit(onSubmit || formData)}
      id="mainForm"
    >
      <div className={smallForm ? 'form-width-sm' : 'form-width'}>
        <div className="row gy-4">
          {title && <p className="text-capitalize fw-semibold fs-4">{title}</p>}
          {customElement && <div>{customElement}</div>}
          {inputWithDropdownArr?.map((e, i) => (
            <div className={e.colValue || ''} style={{ order: e.order }} key={`formInput${i}`}>
              <InputWithDropdown
                {...e}
                errors={errors}
                control={control}
                setValue={setValue}
                register={register}
              />
            </div>
          ))}

          {inputArr?.map((e, i) => (
            <>
              <div className={e.colValue || ''} style={{ order: e.order }} key={`formInput${i}`}>
                <Input
                  {...e}
                  setValue={setValue}
                  register={register(e.name)}
                  errors={errors[e.name]?.message}
                  key={`formInput${i}` + e.title}
                />
              </div>
              {type == 'login' && e.type == 'password' && (
                <Link to="../forgotpassword" className="text-capitalize green-txt mt-2 text-end fs-8">
                  {t('onboarding.forgot_password')}?
                </Link>
              )}
            </>
          ))}

          {selectArr?.map((e, i) => (
            <div className={e.colValue || ''} style={{ order: e.order }} key={`formInput${i}`}>
              <Select
                {...e}
                control={control}
                setValue={setValue}
                register={register(e.name)}
                errors={errors[e.name]?.message}
                key={`formInput${i}` + e.title}
              />
            </div>
          ))}

          {textAreaArr?.map((e, i) => (
            <div className={e.colValue || ''} style={{ order: e.order }} key={`formInput${i}`}>
              <TextArea
                {...e}
                errors={errors[e.name]?.message}
                control={control}
                setValue={setValue}
                register={register(e.name)}
                key={`formInput${i}` + e.title}
              />
            </div>
          ))}

          {checkboxArr?.map((e) => (
            <Checkbox register={register(e.name)} title={e.title} errors={errors[e.name]?.message} />
          ))}

          {captcha && (
            <ReCaptcha register={register('recaptchatoken')} errors={errors['recaptchatoken']?.message} />
          )}
          {btnArr?.map((e, i) => (
            <div style={{ order: e.order }} key={`formBtn${i}`}>
              <MainBtn txt={e.title} {...e} />
            </div>
          ))}
          {type == 'register' ? (
            <p className="text-center mt-3 fs-8">
              {t('onboarding.have_account')}?
              <Link to="/login" className="green-txt">
                {' ' + t('onboarding.login')}
              </Link>
            </p>
          ) : type == 'login' && role == 'patient' ? (
            <p className="text-center mt-3 fs-8">
              {t('onboarding.dont_have_account')}?
              <Link to="/patient/signup" className="green-txt">
                {' ' + t('onboarding.signup')}
              </Link>
            </p>
          ) : (
            ''
          )}
        </div>
      </div>
    </form>
  );
}

export default Form;
