function MainBtn({ txt, style, action = () => null, type = 'button', id, form }) {
  return (
    <button
      type={type}
      className={`text-capitalize mainBtn fw-semibold ${style}`}
      onClick={action}
      test-id={id}
      form={form}
    >
      {txt}
    </button>
  );
}

export default MainBtn;
