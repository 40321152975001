import PopupContainer from '../../Popup_Container.jsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../hooks/useFetch.js';
import poLogo from '../../../assets/images/logo.png';
import { useSelector } from 'react-redux';

function PrivacyPoilcyPop({ setPrivacy }) {
  const { main_logo: entityLogo } = useSelector((store) => store.mainFun.entityDetails);

  const [privacyDetails, setPrivacyDetails] = useState();
  const { t } = useTranslation();
  const { fetchData } = useFetch({});

  const btns = [
    {
      txt: t('agree'),
      style: 'green-btn fw-semibold',
      type: 'submit',
      id: 'expertPrivacy'
    }
  ];

  const getPrivacy = async () => {
    await fetchData({ url: 'privacy/check', loading: true, body: {} }).then((result) => {
      if (result?.result?.privacy_id != null) {
        setPrivacyDetails(result.result);
      } else {
        setPrivacy(true);
      }
    });
  };

  useEffect(() => {
    if (localStorage.token) {
      getPrivacy();
    }
  }, [localStorage.token]);

  const handleSubmit = async (e, close) => {
    await fetchData({
      url: 'privacy/submit',
      loading: true,
      body: {
        params: { privacy: privacyDetails?.privacy_id }
      }
    }).then((result) => {
      if (result) {
        setPrivacy(true);
        setPrivacyDetails();
        close();
      }
    });
  };

  return (
    <PopupContainer
      title={t('privacy_policy')}
      btns={btns}
      open={privacyDetails}
      handleSubmit={handleSubmit}
      closeBtn={false}
    >
      <div className="d-flex justify-content-between">
        <img src={poLogo} height={80} />
        <img src={entityLogo} height={80} />
      </div>
      <div dangerouslySetInnerHTML={{ __html: privacyDetails?.privacy }} />
    </PopupContainer>
  );
}
export default PrivacyPoilcyPop;
