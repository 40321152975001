import { useNavigate } from 'react-router-dom';
import Form from '../../components/Form.jsx';
import { useCallback, useState } from 'react';
import useFetch from '../../hooks/useFetch.js';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LoginWelcomeCard from '../../components/LoginWelcomeCard.jsx';

function Login({ portalToken, role }) {
  const { entityDetails } = useSelector((state) => state.mainFun);
  const [apiError, setApiError] = useState();

  const navigate = useNavigate();
  const { fetchData } = useFetch({});
  const { t } = useTranslation();

  const sendLoginData = useCallback(async (data) => {
    data['Portal-Token'] = portalToken;
    data.email = data.email.toLowerCase();
    await fetchData({ url: 'account/login', loading: true, body: { ...data }, returnError: true }).then(
      (result) => {
        if (result) {
          if (result.status == 404) {
            setApiError({ name: 'email', message: t('onboarding.incorrect_credentials', { val: 'email' }) });
          } else if (result.status == 401) {
            setApiError({
              name: 'password',
              message: t('onboarding.incorrect_credentials', { val: 'password' })
            });
          } else if (result.result?.otp_sent) {
            navigate('../otp' + `?token=${result.result?.jwt_token}`);
          } else if (result.result?.token) {
            localStorage.token = result.result?.token;
            if (role == 'patient') {
              if (result.result.case_count == 0) {
                navigate('../newcase');
              } else if (result.result.case_count == 1) {
                if (result.result.request_count == 1 || result.result.draft_case_id) {
                  navigate('../newcase');
                } else {
                  navigate(`../case/${result.result.case_id}`);
                }
              } else {
                navigate('../home');
              }
            } else {
              navigate('../home');
            }
          }
        }
      }
    );
  }, []);

  let inputArr = [
    {
      title: t('onboarding.email'),
      placeholder: t('enter_your', { val: 'onboarding.email' }),
      name: 'email'
    },
    {
      title: t('onboarding.password'),
      placeholder: t('enter_your', { val: 'onboarding.password' }),
      type: 'password',
      name: 'password'
    }
  ];

  let btnArr = [
    {
      title: t('onboarding.login'),
      style: 'green-btn',
      type: 'submit'
    }
  ];

  const title = entityDetails?.name;
  const mainWebsite = entityDetails?.main_website;
  const login_welcome = import.meta.env.VITE_LOGIN_WELCOME;
  return (
    title && (
      <div>
        <div className="row row-cols-sm-2 g-3 align-content-center justify-content-center">
          {role == 'patient' &&
            (login_welcome ? (
              <LoginWelcomeCard />
            ) : (
              <div className="welcome-txt">
                <p className="fs-1 fw-bold">
                  {t('onboarding.login_welcome.0') + ' '}
                  <span className="green-txt">{title}</span>
                  {' ' + t('onboarding.login_welcome.1')}
                </p>
                <p className="fs-8 grey-txt">
                  {t('onboarding.login_welcome.2') + ' '}
                  <span className="green-txt">{title}</span>
                  {' ' + t('onboarding.login_welcome.3') + ': '}
                  <a href={mainWebsite} target="_blank" className="green-txt" name="entity_website">
                    {mainWebsite?.split('//')?.pop()}
                  </a>
                </p>
              </div>
            ))}
          <div className="d-flex justify-content-center">
            <Form
              title={t('onboarding.login')}
              inputArr={inputArr}
              type="login"
              btnArr={btnArr}
              onSubmit={sendLoginData}
              smallForm={true}
              apiError={apiError}
              key="login"
            />
          </div>
        </div>
      </div>
    )
  );
}
export default Login;
