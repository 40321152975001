import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import eye from '../assets/images/svg/eye-slash.svg';

function Input({
  title,
  placeholder,
  type,
  register,
  errors,
  setValue,
  disabled,
  required,
  onBlur,
  onChange,
  value,
  name,
  min,
  max,
  centerTitle = false,
  inputRef,
  maxLength
}) {
  const [inputConfig, setInputConfig] = useState();

  let passwordRef = useRef();
  const showPassword = () => {
    let input = passwordRef.current.previousSibling;
    input.type == 'text' ? (input.type = 'password') : (input.type = 'text');
  };

  useLayoutEffect(() => {
    let obj = {
      type: type || 'text'
    };

    if (required) obj['required'] = true;
    if (disabled) obj['disabled'] = true;
    if (min) obj['min'] = min;
    if (max) obj['max'] = max;
    if (maxLength) obj['maxLength'] = maxLength;
    if (placeholder) obj['placeholder'] = placeholder;
    if (!register && name) obj['name'] = name;

    setInputConfig(obj);
  }, [min, max, disabled, required, placeholder, type, name, register, maxLength]);

  useEffect(() => {
    if (value && setValue) setValue(register?.name, value);
  }, [value]);

  return (
    <div>
      {title && (
        <p className={`mb-2 ${centerTitle ? 'text-center' : ''} text-capitalize fw-medium`}>{title}</p>
      )}
      <div className="input-group">
        <input
          className={`mainInput form-control ${type == 'password' ? 'border-end-0' : ''} ${
            errors && 'err-active'
          } ${title ? '' : 'clearBordersLeft'}`}
          {...inputConfig}
          {...register}
          onBlur={(e) => {
            register?.onBlur(e);
            onBlur && onBlur(e);
          }}
          onChange={(e) => {
            register?.onChange(e);
            onChange && onChange(e);
          }}
          ref={(e) => {
            inputRef && (inputRef.current = e);
            register?.ref(e);
          }}
          defaultValue={value || ''}
          test-id={'input-' + (register?.name || name)}
        />
        {type == 'password' && (
          <span
            className={`input-group-text border-start-0 search ${errors && 'err-active'}`}
            ref={passwordRef}
            onClick={showPassword}
          >
            <img className="pointer icon-color" src={eye} alt="eye" />
          </span>
        )}
      </div>
      {errors && <p className="text-capitalize err-msg mt-2 fs-8">{errors.replace('_', ' ')}</p>}
    </div>
  );
}

export default Input;
