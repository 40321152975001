import moment from 'moment';
import { Link } from 'react-router-dom';
import MainBtn from '../components/Button.jsx';
import TransferMDTLeadPop from '../components/popups/expertActionBars/Transfer_MDT_Lead_Pop.jsx';

export const patientCaseListTable = (e, t, caseInProgress) => [
  {
    label: e.name,
    className: 'w-25'
  },
  {
    label: e.state
  },
  {
    label: e.patient_name
  },
  {
    label: (
      <u className="green-txt">
        <Link className="green-txt fw-bold" to={caseInProgress ? `../newcase?id=${e.id}` : `../case/${e.id}`}>
          {caseInProgress ? t('case_list.continue_case') : t('case_list.view')}
        </Link>
      </u>
    )
  }
];

export const expertCaseListTable = (e, t) => [
  {
    label: e.name,
    className: 'w-25'
  },
  {
    label: e.patient_first_name
  },
  {
    label: e.patient_last_name
  },
  {
    label: e.assign_expert_date ? moment(e.assign_expert_date).format('DD/MM/YYYY') : 'no date available'
  },
  {
    label: e.acceptation_date ? moment(e.acceptation_date).format('DD/MM/YYYY') : 'no date available'
  },
  {
    label: e.state
  },
  {
    label: <p className="text-danger">{e.entity_service_type && e.entity_service_type.toUpperCase()}</p>
  },
  {
    label: (
      <u className="green-txt">
        <Link className="green-txt fw-bold" to={`../case/${e.id}`}>
          {t('case_list.view')}
        </Link>
      </u>
    )
  }
];

export const medicineTable = (medicine) => [
  { label: medicine.name },
  { label: medicine.dose },
  { label: medicine.frequency },
  { label: medicine.duration },
  { label: medicine.indication_of_use }
];

export const operationTable = (data) => Object.entries(data).map(([key, value]) => ({ label: value }));

export const vcTable = (data, removeSlot, caseDetails, t) => [
  { label: moment(data.slot_date).format('DD/MM/YYYY') },
  { label: moment(data.slot_date).format('h:mm A') },
  { label: data.confirmed ? 'accepted' : 'pending' },
  {
    label:
      data.confirmed || caseDetails.vc?.room_status != 'incoming_room' ? null : (
        <MainBtn
          txt={t('remove')}
          style="text-decoration-underline bg-transparent red-txt p-1 text-start"
          action={() => removeSlot([{ slot_id: data.slot_id }])}
        />
      )
  }
];

export const mdtTable = (e, removeExpert, caseDetails, t, isCaseClosed) => {
  let obj = caseDetails?.mdt_settings?.enable_mdt_team_accept_reject
    ? [{ label: e.name }, { label: e.sub_specialization }, { label: e.acceptance_status }]
    : [{ label: e.name }, { label: e.sub_specialization }];

  isCaseClosed &&
    obj.push({
      label: (
        <div className="d-flex gap-5">
          <TransferMDTLeadPop
            exId={e}
            trigger={{
              txt: t('case_view.btns.transfer_leadership'),
              style: 'text-decoration-underline bg-transparent text-nowrap p-0 text-start green-txt'
            }}
          />
          <MainBtn
            style="text-decoration-underline bg-transparent red-txt p-0 text-start"
            txt={t('remove')}
            action={() => removeExpert(e.expert_id)}
          />
        </div>
      )
    });
  return obj;
};
