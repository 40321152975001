import React, { useState, useEffect, useLayoutEffect } from 'react';
import Search from '../../components/Search.jsx';
import Table from '../../components/Table.jsx';
import useFetch from '../../hooks/useFetch.js';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { expertCaseListTable, patientCaseListTable } from '../../utils/tables-config.jsx';

function CaseList() {
  const { role } = useSelector((state) => state.mainFun.user);

  const [caseListData, setCaseListData] = useState([]);
  const [searchedData, setSearchedData] = useState();
  const [tHead, setTHead] = useState();
  const { data } = useFetch({ url: 'case/list', loading: true, body: {} });
  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (role == 'patient') {
      setTHead(t('case_list.patient_head', { returnObjects: true }));
    } else {
      setTHead(t('case_list.expert_head', { returnObjects: true }));
    }
  }, []);

  useEffect(() => {
    if (data?.result?.cases) {
      const newList = [];
      if (role == 'patient') {
        data.result.cases = data.result.cases.sort((a, b) => b.id - a.id);
        data.result.requests_list?.map((e) => {
          newList.push(
            patientCaseListTable(
              {
                name: e.request_number,
                state: 'draft',
                patient_name: e.partner_name,
                id: e.partner_id?.[0]
              },
              t,
              true
            )
          );
        });
        data.result.cases.map((e) => {
          let state = e.state.toLowerCase();
          let caseInProgress = false;

          if (state === 'draft') {
            caseInProgress = true;
            e.id = e.patient_id;
          }

          ['data collection'].includes(state) && e.payment_status == 'pending'
            ? (e.state = t('status.pending_payment_confirmation'))
            : ['data collection'].includes(state) && e.payment_status == 'paid'
            ? (e.state = t('status.confirmed_payment'))
            : ['assigned', 'under review'].includes(state)
            ? (e.state = t('status.under_case_manager_review'))
            : ['sent to expert', 'accepted'].includes(state)
            ? (e.state = t('status.expert_review'))
            : (e.state = t(`status.${state.replaceAll(' ', '_')}`));
          newList.push(patientCaseListTable(e, t, caseInProgress));
        });
      } else if (role == 'expert') {
        data.result.cases.map((e) => {
          !e.patient_first_name && (e.patient_first_name = e.patient_name?.split(' ')[0]);
          !e.patient_last_name && (e.patient_last_name = e.patient_name?.split(' ')[1]);
          e.state = t(`status.${e.state.toLowerCase().replaceAll(' ', '_')}`);
          newList.push(expertCaseListTable(e, t));
        });
      }
      setCaseListData(newList);
    }
  }, [data]);

  return (
    <div>
      <Search setSearchedData={setSearchedData} caseListData={caseListData} />
      {caseListData.length > 0 ? (
        <div className="mb-4">
          <Table tHead={tHead} data={searchedData ? searchedData : caseListData} caseView={true} />
        </div>
      ) : (
        <p className="text-center mt-5">{t('case_list.no_cases')}</p>
      )}
    </div>
  );
}
export default CaseList;
