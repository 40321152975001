import { createSlice } from '@reduxjs/toolkit';

const initialConfigState = {
  discussionBoard: true,
  followUpQuestions: true,
  concernQuestions: true,
  typedReport: true,
  uploadReport: true,
  finalOpinionSection: true,
  submitBtn: true
};
export const caseDetailsSlice = createSlice({
  name: 'caseDetails',
  initialState: {
    details: false,
    reRender: true,
    config: initialConfigState,
    refetch: false,
    isMdt: false,
    isVc: false
  },
  reducers: {
    setCaseDetails: (state, action) => {
      state.details = action.payload;
      state.refetch = false;
      const checkOnServiceType = (service) => {
        if (
          (action.payload?.service == service &&
            action.payload.suggested_service?.patient_acceptance_status != 'accepted') ||
          (action.payload.suggested_service?.type == service &&
            (!action.payload.suggested_service?.cm_suggestion_acceptance_status ||
              action.payload.suggested_service?.cm_suggestion_acceptance_status == 'accepted') &&
            action.payload.suggested_service?.patient_acceptance_status == 'accepted')
        ) {
          console.log('ss', action.payload.suggested_service?.patient_acceptance_status);
          return true;
        }
        return false;
      };
      state.isMdt = checkOnServiceType('mdt');
      state.isVc = checkOnServiceType('vc');
    },
    updateCaseDetails: (state, action) => {
      if (state.details)
        action.payload.key2
          ? (state.details[action.payload.key] = {
              ...state.details[action.payload.key],
              [action.payload.key2]: action.payload.value
            })
          : (state.details[action.payload.key] = action.payload.value);
    },
    changeCaseState: (state, action) => {
      state.details.state = action.payload;
      state.reRender = true;
    },
    submitCase: (state, action) => {
      state.details.submit_final_opinion = true;
      state.reRender = true;
    },
    submitFollow: (state, action) => {
      state.details.submit_follow_up_opinion = true;
      state.reRender = true;
    },
    caseReRender: (state, action) => {
      state.reRender = true;
    },
    unRender: (state, action) => {
      state.reRender = false;
    },
    addFiles: (state, action) => {
      state.details.attachment_expert_report_ids.push(...action.payload);
    },
    removeFiles: (state, action) => {
      state.details.attachment_expert_report_ids.splice(action.payload, 1);
    },
    setCaseConfig: (state, action) => {
      action.payload?.forEach(({ key, value }) => {
        state.config[key] = value;
      });
    },
    resetCaseState: (state, action) => {
      state.config = initialConfigState;
      state.details = false;
      state.isMdt = false;
      state.isVc = false;
      state.reRender = true;
    },
    refetchCaseDetails: (state, action) => {
      state.refetch = true;
    }
  }
});

export const {
  setCaseDetails,
  changeCaseState,
  submitCase,
  submitFollow,
  caseReRender,
  unRender,
  addFiles,
  removeFiles,
  updateCaseDetails,
  setCaseConfig,
  resetCaseState,
  refetchCaseDetails
} = caseDetailsSlice.actions;

export default caseDetailsSlice.reducer;
