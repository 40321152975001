import useFetch from '../../hooks/useFetch.js';
import { useTranslation } from 'react-i18next';
import 'quill/dist/quill.snow.css';

function TermsOfUse({ portalToken }) {
  const { t } = useTranslation();

  const { data } = useFetch({
    url: 'website/terms',
    body: { params: { 'Portal-Token': portalToken } },
    loading: true
  });

  const htmlDecode = (input) => {
    let doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <p className="fs-3 fw-bold mb-5">{t('terms_use')}</p>
      <div id="editor" className="ql-editor w-100">
        <div
          className="d-flex flex-column gap-3 primary-txt"
          dangerouslySetInnerHTML={{
            __html: ['false', 'False', false].includes(data?.result?.content)
              ? '<p>No text available</p>'
              : data?.result?.content
          }}
        />
      </div>
    </div>
  );
}

export default TermsOfUse;
