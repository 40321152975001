import { useSelector } from 'react-redux';
import cases from '../assets/images/svg/cases.svg';
import ThemeToggle from './ThemeToggle.jsx';
import { useNavigate } from 'react-router-dom';
import LanguageDropdown from './Language_Dropdown.jsx';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

const SideBar = ({ options, showSideBar, setShowSideBar }) => {
  const userDetails = useSelector((state) => state.mainFun.user);

  const [allOptions, setAllOptions] = useState([]);

  const navigate = useNavigate();
  const sideBarRef = useRef();
  const bottomRef = useRef();

  const login = Boolean(localStorage.token);
  const userName = login
    ? userDetails.firstName
      ? userDetails.firstName + ' ' + userDetails.lastName
      : userDetails.name
    : '';

  const logOut = options.pop();

  useLayoutEffect(() => {
    if (userDetails && login) {
      if (userDetails.role == 'expert') {
        options.shift();
      }
      setAllOptions([
        {
          txt: 'cases',
          action: () => navigate(`${userDetails.role}/home`),
          img: cases
        },
        ...options
      ]);
    } else {
      setAllOptions([]);
    }
  }, [userDetails, login]);

  const hideSideBar = (e) =>
    sideBarRef.current.classList.contains('animate__slideOutRight') &&
    sideBarRef.current.classList.remove('active');

  useEffect(() => {
    if (showSideBar) {
      const sideBarToggle = document.querySelector('#sidebar-toggle');
      document.onclick = (e) =>
        !sideBarToggle.contains(e.target) && !bottomRef.current.contains(e.target) && setShowSideBar(false);
    } else {
      document.onclick = null;
    }
  }, [showSideBar]);

  useEffect(() => {
    if (showSideBar) {
      sideBarRef.current.classList.add('active');
      sideBarRef.current.classList.add('animate__slideInRight');
      sideBarRef.current.classList.remove('animate__slideOutRight');
    } else {
      sideBarRef.current.classList.add('animate__slideOutRight');
      sideBarRef.current.classList.remove('animate__slideInRight');
    }
  }, [showSideBar]);

  return (
    <div
      className={'sidebar animate__animated d-sm-none '}
      ref={sideBarRef}
      onAnimationEnd={hideSideBar}
      data-bs-auto-close="true"
    >
      {userName && (
        <div className="text-center fw-bold bg-green py-2 rounded-3">
          <p className="green-txt">
            {userDetails.role == 'expert' && <b>Dr.</b>}
            {userName}
          </p>
        </div>
      )}

      <div className="menu-bar">
        <ul className="menu-links p-0 mt-2">
          {allOptions?.map((e) => (
            <li className="nav-link px-3 list" onClick={e.action} key={'sidebar' + e.txt}>
              <div className="d-flex gap-3">
                <img src={e.img} width={25} className="icon-color" />
                <span className="text nav-text text-capitalize">{e.txt}</span>
              </div>
            </li>
          ))}
        </ul>

        <div className="bottom-content">
          {login && (
            <li className="nav-link list">
              <div className="d-flex gap-3 px-3" onClick={logOut.action}>
                <img src={logOut.img} width={25} className="icon-color" />
                <span className="text nav-text">{logOut.txt}</span>
              </div>
            </li>
          )}

          <div className="mt-4" ref={bottomRef}>
            {import.meta.env.VITE_ENABLE_LOCALIZATION == 'true' && (
              <div className="bg-grey rounded-3 py-2 px-3">
                <LanguageDropdown showText={true} />
              </div>
            )}
            <ThemeToggle toggler={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
