import { Navigate, Route, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { lazy, Suspense, useState } from 'react';
import useFetch from './hooks/useFetch.js';
import SelectPortal from './pages/common/select_portal.jsx';
import { setUserDetails } from './features/main_functions.js';
import { Layout } from './layouts/Layout.jsx';
import { tracker } from './config.js';
const PatientRoutes = lazy(() => import('./pages/patient/patient.routes.jsx'));
const ExpertRoutes = lazy(() => import('./pages/expert/expert.routes.jsx'));
const OperationRoutes = lazy(() => import('./pages/operation/operation.routes.jsx'));

function App() {
  const { pathname } = useLocation();
  const [apiRole, setApiRole] = useState(); //role from the user role api
  const portals = ['patient', 'expert', 'operation'];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { fetchData } = useFetch({});
  const { token } = localStorage;

  const fetchUserRole = async ({ redirect }) => {
    await fetchData({ url: 'user/type', loading: false, body: {} }).then((result) => {
      if (result?.result) {
        const userDetails = { ...result.result };
        userDetails.user_type = userDetails.user_type;
        dispatch(setUserDetails(userDetails));
        setApiRole(userDetails.user_type);
        tracker.setUserID(userDetails.user_type + ' - ' + userDetails.email);
        if (pathname.split('/')[1] !== userDetails.user_type && redirect) {
          navigate('/' + userDetails.user_type);
        }
      }
    });
  };

  const RedirectRoute = () => {
    if (portals.includes(apiRole) && token) {
      return <Navigate to={`/${apiRole}`} />;
    } else if (!apiRole && !token) {
      return <SelectPortal setRole={setApiRole} />;
    } else {
      fetchUserRole({ redirect: true });
      return null;
    }
  };

  const RedirectPortalRoute = ({ element, role }) => {
    if ((apiRole && apiRole === role && token) || !token) {
      return element;
    } else {
      fetchUserRole({ redirect: true });
      return null;
    }
  };

  return (
    <Layout setApiRole={setApiRole} fetchUserRole={fetchUserRole} apiRole={apiRole}>
      <Route exact path="/" element={<RedirectRoute />} />
      <Route
        path="/patient/*"
        element={
          <RedirectPortalRoute
            element={
              <main>
                <Suspense>
                  <PatientRoutes />
                </Suspense>
              </main>
            }
            role="patient"
          />
        }
      />
      <Route
        path="/expert/*"
        element={
          <RedirectPortalRoute
            element={
              <main>
                <Suspense>
                  <ExpertRoutes />
                </Suspense>
              </main>
            }
            role="expert"
          />
        }
      />
      <Route
        path="/operation/*"
        element={
          <RedirectPortalRoute
            element={
              <main>
                <Suspense>
                  <OperationRoutes />
                </Suspense>
              </main>
            }
            role="operation"
          />
        }
      />
    </Layout>
  );
}

export default App;
