import trash from '../../assets/images/svg/trash.svg';
import closeIcon from '../../assets/images/svg/close.svg';
import download from '../../assets/images/svg/download.svg';
import other from '../../assets/images/svg/other.svg';
import { lazy, Suspense, useLayoutEffect, useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import useFetch from '../../hooks/useFetch.js';
import MainBtn from '../Button.jsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import arrow from '../../assets/images/svg/arrow.svg';
const PdfViewer = lazy(() => import('./PDF_Viewer.jsx'));

function FileView({ file, getFileType, type, setShowFile, removeFile, deleteFileRef }) {
  const caseDetails = useSelector((state) => state.caseDetails.details);

  const filesKey =
    file.viewType == 'expert_report'
      ? 'attachment_expert_report_ids'
      : caseDetails.user_role == 'patient'
      ? 'patient_medical_files'
      : 'attachment_patient_files_ids';

  const allFiles = caseDetails[filesKey];

  const [fileUrl, setFileUrl] = useState({});
  const [imageScale, setImageScale] = useState(1.0);
  const [fileType, setFileType] = useState(type);
  const [currentFile, setCurrentFile] = useState(file);
  const [showNavBtn, setShowNavBtn] = useState({ left: false, right: false });

  const imgRef = useRef();
  const { fetchData } = useFetch({});
  const { t } = useTranslation();

  const navigateFiles = (isNext) => {
    const currentIndex = allFiles.findIndex((file) => file.id === currentFile.id);
    if (isNext && currentIndex + 1 < allFiles.length) {
      setCurrentFile(allFiles[currentIndex + 1]);
      setFileType(getFileType(allFiles[currentIndex + 1], true));
    } else if (!isNext && currentIndex - 1 >= 0) {
      setCurrentFile(allFiles[currentIndex - 1]);
      setFileType(getFileType(allFiles[currentIndex - 1], true));
    }
  };

  let getDicom = async () =>
    await fetchData({
      url: `view_dicom/${currentFile.id}`,
      loading: false,
      body: { params: { is_dicom: true } }
    }).then((result) => {
      if (result) {
        setFileUrl((e) => ({ ...e, externalUrl: result.result?.url }));
      }
    });

  const createFileUrl = (targetFile) => {
    if (targetFile?.url) {
      let url = targetFile.url.includes('https') ? targetFile.url : targetFile.url.replace('http', 'https');
      setFileUrl({ url });
    } else {
      setFileUrl({ url: URL.createObjectURL(targetFile) });
    }
  };

  const viewFile = () => {
    const fileElm = document.getElementById('fileCard-' + currentFile.id);
    if (fileElm) fileElm.style = 'filter: grayscale(1)';
  };

  let obj = {
    video: (
      <video controls className="w-100">
        <source src={fileUrl.url} type={currentFile.mimetype} />
      </video>
    ),
    img: (
      <div>
        <img src={fileUrl.url} alt={currentFile.name} ref={imgRef} />
      </div>
    ),
    pdf: (
      <Suspense>
        <PdfViewer fileUrl={fileUrl.url} />
      </Suspense>
    ),
    dicom: (
      <div className="d-flex flex-column gap-3">
        <img className="mb-2" src={other} style={{ width: '10em' }} />
        {fileUrl?.externalUrl?.length > 0 ? (
          fileUrl.externalUrl.map((di, i) => (
            <a className="text-primary" href={di} target="_blank">
              <MainBtn txt={t('case_list.view') + ' study ' + (i + 1)} style="green-btn" />
            </a>
          ))
        ) : (
          <p>{t('loading') + ' ' + t('please_wait')}...</p>
        )}
      </div>
    ),
    other: <p>{t('case_view.popups.no_view')}</p>
  };

  const zoomImage = (zoomType) => {
    const isValidZoomIn = zoomType === 'in' && imageScale < 2;
    const isValidZoomOut = zoomType === 'out' && imageScale > 0.6;

    if (isValidZoomIn || isValidZoomOut) {
      setImageScale((prev) => (isValidZoomIn ? prev + 0.1 : prev - 0.1));
      imgRef.current.style.width = `${imageScale * 100 + (isValidZoomIn ? 0.1 : -0.1) * 100}%`;
    }
  };

  const downloadFile = () => {
    const a = document.createElement('a');
    a.href = fileUrl.url;
    a.download = currentFile.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  useLayoutEffect(() => {
    if (caseDetails) {
      viewFile();
      setShowNavBtn({
        left: allFiles.findIndex((file) => file.id === currentFile.id) > 0,
        right: allFiles.findIndex((file) => file.id === currentFile.id) < allFiles.length - 1
      });
    }
    if (fileType == 'dicom') {
      createFileUrl(currentFile);
      getDicom();
    } else {
      createFileUrl(currentFile);
    }
  }, [currentFile]);

  return (
    <div>
      <Popup
        open
        nested
        closeOnDocumentClick
        onClose={() => setShowFile(false)}
        className="popup-file-style"
        key="fileViewer"
      >
        {(close) => (
          <>
            <div className="d-flex flex-column popup-style w-100 mh-100 text-center">
              <div className="pb-3 pt-1 d-flex align-items-center justify-content-between">
                <p className="fs-7 fw-semibold text-capitalize text-wrap">{currentFile.name}</p>
                {fileType == 'img' && (
                  <p className="fs-5 px-4 d-flex">
                    <span className="pointer text-primary fw-semibold" onClick={() => zoomImage('out')}>
                      -
                    </span>{' '}
                    {Math.floor(imageScale * 100)}%{' '}
                    <span className="pointer text-primary fw-semibold" onClick={() => zoomImage('in')}>
                      +
                    </span>
                  </p>
                )}
                <div className="d-flex flex-row gap-3 pointer">
                  {(currentFile.isDeleted || currentFile.uploaded) && (
                    <img
                      src={trash}
                      ref={deleteFileRef}
                      className="icon-color"
                      onClick={() => {
                        removeFile(currentFile);
                        close();
                      }}
                    />
                  )}
                  {!currentFile.uploaded && (
                    <img src={download} className="icon-color" onClick={downloadFile} />
                  )}
                  <img src={closeIcon} onClick={close} className="icon-color" />
                </div>
              </div>
              <div className="file-pad">{obj[fileType]}</div>
            </div>
            {showNavBtn && (
              <div className="d-flex gap-5">
                <NavigateIcon isNext={false} show={showNavBtn.left} action={navigateFiles} />
                <NavigateIcon isNext={true} show={showNavBtn.right} action={navigateFiles} />
              </div>
            )}
          </>
        )}
      </Popup>
    </div>
  );
}

export default FileView;

export const NavigateIcon = ({ isNext, show, action, size = '2.5em' }) => {
  return (
    <div
      className={'circle-arrow-icon bg-green pointer ' + (!show && 'invisible')}
      style={{ width: size, height: size }}
      onClick={() => action(isNext)}
    >
      <img
        src={arrow}
        alt={isNext ? 'next' : 'previous'}
        className={'icon-color ' + (isNext ? 'right' : 'left')}
      />
    </div>
  );
};
