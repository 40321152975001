import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './lang/en/en.js';
import fr from './lang/fr/fr.js';
import ar from './lang/ar/ar.js';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          ...en
        }
      },
      fr: {
        translation: {
          ...fr
        }
      },
      ar: {
        translation: {
          ...ar
        }
      }
    },
    lng: localStorage.lang,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
