import Input from './Input';
import Select from './Select';

function InputWithDropdown({
  title,
  placeholder,
  register,
  errors,
  data,
  setValue,
  selectName,
  inputName,
  inputValue,
  selectValue,
  control
}) {
  return (
    <div>
      <p className="text-capitalize fw-medium mb-2">{title}</p>
      <div className="d-flex">
        <div className="w-25">
          <Select
            register={register(selectName)}
            name={selectName}
            data={data}
            errors={errors[selectName]?.message}
            value={selectValue}
            control={control}
            setValue={setValue}
          />
        </div>
        <div className="w-75">
          <Input
            placeholder={placeholder}
            register={register(inputName)}
            type="number"
            errors={errors[inputName]?.message}
            value={inputValue}
            setValue={setValue}
          />
        </div>
      </div>
    </div>
  );
}

export default InputWithDropdown;
