import { AwsRum } from 'aws-rum-web';

const {
  VITE_RUM_IDENTITYPOOL,
  VITE_RUM_ENDPOINT,
  VITE_RUM_APP_ID,
  VITE_ALLOW_RUM,
  VITE_RUM_REGION
} = import.meta.env;

let awsRum;

if (VITE_ALLOW_RUM) {
  try {
    const config = {
      sessionSampleRate: 1,
      identityPoolId: VITE_RUM_IDENTITYPOOL,
      endpoint: VITE_RUM_ENDPOINT,
      telemetries: ['performance', 'errors', 'http'],
      allowCookies: true,
      enableXRay: false
    };

    const APPLICATION_ID = VITE_RUM_APP_ID;
    const APPLICATION_VERSION = '1.0.0';
    const APPLICATION_REGION = VITE_RUM_REGION;
    awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
    awsRum.disable();
  } catch (error) {
    console.log("🚀 ~ error:", error)
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
}

export { awsRum };
