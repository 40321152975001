import { lazy, Suspense } from 'react';
const LtrLayout = lazy(() => import('./LtrLayout.jsx'));
const RtrLayout = lazy(() => import('./RtlLayout.jsx'));

const LayoutDir = ({ children }) => {
  const dir = localStorage.lang == 'ar' ? 'rtl' : 'ltr';

  if (dir === 'rtl') {
    return (
      <Suspense fallback={<></>}>
        <RtrLayout>{children}</RtrLayout>
      </Suspense>
    );
  } else {
    return (
      <Suspense fallback={<></>}>
        <LtrLayout>{children}</LtrLayout>
      </Suspense>
    );
  }
};

export default LayoutDir;
