import { useNavigate } from 'react-router-dom';
import Form from '../../components/Form.jsx';
import { useCallback, useState } from 'react';
import MainBtn from '../../components/Button.jsx';
import useFetch from '../../hooks/useFetch.js';
import { useTranslation } from 'react-i18next';

function ForgetPassword({ portalToken }) {
  const [apiError, setApiError] = useState('');
  const [emailSent, setEmailSent] = useState(false);

  const navigate = useNavigate();
  const { fetchData } = useFetch({});
  const { t } = useTranslation();

  const sendForgetPasswordData = useCallback(async (data) => {
    await fetchData({
      url: 'password/forgot',
      loading: true,
      body: { params: { email: data.email.toLowerCase(), 'Portal-Token': portalToken } }
    }).then((result) => {
      if (result && !result?.error) {
        setEmailSent(true);
      } else if (result?.error) {
        setApiError(result?.error);
      }
    });
  }, []);

  let inputArr = [
    {
      title: t('onboarding.email'),
      placeholder: t('enter_your', { val: 'onboarding.email' }),
      name: 'email'
    }
  ];

  let btnArr = [
    {
      title: t('onboarding.reset_password'),
      style: 'green-btn',
      type: 'submit'
    },
    {
      title: t('onboarding.back_login'),
      style: 'grey-btn',
      action: () => navigate('../login')
    }
  ];

  return emailSent ? (
    <div className="d-flex justify-content-center text-center mt-5">
      <div className="form-width">
        <p className="fs-1">{t('onboarding.email_sent')}</p>
        <MainBtn txt={t('onboarding.back_login')} style="green-btn" action={() => navigate('../login')} />
      </div>
    </div>
  ) : (
    <Form
      title={t('onboarding.forgot_password')}
      inputArr={inputArr}
      type="forgot password"
      btnArr={btnArr}
      onSubmit={sendForgetPasswordData}
      apiError={apiError}
      smallForm={true}
      key="forgotPassword"
    />
  );
}

export default ForgetPassword;
