import { useEffect, useState } from 'react';
import MainBtn from '../Button.jsx';
import ErrorGuidesPop from '../popups/common/Error_Guides_Pop.jsx';
import moment from 'moment';

const ErrorTemplate = () => {
  const [openPop, setOpenPop] = useState(false);
  const [ipAddress, setIpAddress] = useState('');

  const steps = [
    'Refresh the page',
    'Check your network connection',
    'Change your internet connection',
    "Make sure you're not using VPN",
    'Contact support'
  ];

  const getIpAddress = async () => {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    console.log(data);
    setIpAddress(data.ip);
  };

  useEffect(() => {
    getIpAddress();
  }, []);

  return (
    <div className="w-100 vh-100 d-flex flex-column justify-content-center align-items-center text-center p-2">
      <ErrorGuidesPop open={openPop} setOpenPop={setOpenPop} steps={steps} />
      <img src="/server-error.png" alt="error" width={1} height={1} className="invisible" />
      <h2 className="fw-bold text-danger">
        Something went wrong, try following these{' '}
        <span
          className="text-danger-emphasis pointer text-decoration-underline"
          onClick={() => setOpenPop(true)}
        >
          steps
        </span>
      </h2>
      <h5 className="fw-bold">If the problem persists, please contact support.</h5>
      <u>
        <a href={`mailto:${import.meta.env.VITE_SUPPORT_EMAIL}`} className="fs-7 text-primary">
          {import.meta.env.VITE_SUPPORT_EMAIL}
        </a>
      </u>
      <p>{ipAddress}</p>
      <p>{moment().format('DD-MM-YYYY HH:mm:ss')}</p>
      <div className="mt-3">
        <MainBtn txt="Refresh Page" style="bg-primary text-white" action={() => window.location.reload()} />
      </div>
    </div>
  );
};

export default ErrorTemplate;
