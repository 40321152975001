import Form from '../../components/Form.jsx';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../features/main_functions.js';
import { useSearchParams } from 'react-router-dom/dist/index.js';
import useFetch from '../../hooks/useFetch.js';
import { useTranslation } from 'react-i18next';

function ResetPassword() {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { fetchData } = useFetch({});
  const { t } = useTranslation();

  const token = searchParams.get('token');

  useEffect(() => {
    token && sendResetPasswordToken();
  }, []);

  const sendResetPasswordToken = useCallback(async () => {
    await fetchData({
      url: 'password/token/validator',
      loading: false,
      body: {
        params: {
          token: token
        }
      }
    }).then((result) => {
      if (!result?.result) {
        navigate('/login');
      }
    });
  }, []);

  const sendResetPasswordData = useCallback(async (data) => {
    await fetchData({
      url: 'password/reset',
      loading: true,
      body: {
        params: {
          password: data.password,
          token: token
        }
      }
    }).then((result) => {
      if (result) {
        dispatch(setAlert({ txt: t('alert_box.password_reset'), type: 'success' }));
        navigate('../login');
      }
    });
  }, []);

  let inputArr = [
    {
      title: t('onboarding.password'),
      placeholder: 'enter password',
      type: 'password',
      name: 'password'
    },
    {
      title: t('onboarding.confirm_password'),
      placeholder: 'enter password',
      type: 'password',
      name: 'confirm password'
    }
  ];

  let btnArr = [
    {
      title: t('onboarding.reset_password'),
      style: 'green-btn',
      type: 'submit'
    }
  ];

  return (
    <div>
      <Form
        title={t('onboarding.reset_password')}
        inputArr={inputArr}
        btnArr={btnArr}
        type="reset password"
        onSubmit={sendResetPasswordData}
        smallForm={true}
        key="resetPassword"
      />
    </div>
  );
}

export default ResetPassword;
