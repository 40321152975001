import MainBtn from './Button';
import notify from '../assets/images/svg/notification.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import user from '../assets/images/svg/profile.svg';
import lock from '../assets/images/svg/lock.svg';
import logoutIcon from '../assets/images/svg/logout.svg';
import DropDown from './Dropdown.jsx';
import { useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LanguageDropdown from './Language_Dropdown.jsx';
import { useTranslation } from 'react-i18next';
import ThemeToggle from './ThemeToggle.jsx';
import SideBar from './SideBar.jsx';
import breadcrumb from '../assets/images/svg/breadcrumb.svg';
import close from '../assets/images/svg/close.svg';
import { setUserDetails } from '../features/main_functions.js';

function NavBar({ setApiRole }) {
  const { role, ...userDetails } = useSelector((state) => state.mainFun.user);
  const { entityDetails } = useSelector((state) => state.mainFun);
  const caseDetails = useSelector((state) => state.caseDetails.details);

  const [showSideBar, setShowSideBar] = useState(false);

  const { pathname } = useLocation();
  const ref = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const login = Boolean(localStorage.token);
  const userName = userDetails.firstName
    ? userDetails.firstName + ' ' + userDetails.lastName
    : userDetails.name;

  const logOut = () => {
    dispatch(setUserDetails({ role }));
    localStorage.removeItem('token');
    setApiRole && setApiRole();
    navigate(`${role}/login`);
  };

  const navigateToChangePassword = () => navigate(`${role}/changePassword`);

  const navigateToProfile = () => navigate('patient/profile');

  const dropDownOptions = [
    {
      txt: t('navbar.profile'),
      action: navigateToProfile,
      img: user
    },
    {
      txt: t('navbar.change_password'),
      action: navigateToChangePassword,
      img: lock
    },
    {
      txt: t('navbar.logout'),
      action: logOut,
      img: logoutIcon
    }
  ];

  useLayoutEffect(() => {
    if (pathname.split('/').includes('home')) {
      ref.current?.classList?.add('active');
    } else {
      ref.current?.classList?.remove('active');
    }
  }, [pathname]);

  const toggleSidebar = () => setShowSideBar(!showSideBar);

  return (
    <>
      <nav className="navbar animate__animated animate__slideInDown">
        <div className="d-flex gap-2 justify-content-between w-100 align-items-center">
          <div className="d-flex gap-2 gap-sm-4 align-items-center">
            <Link to="/">
              <img
                src={entityDetails?.main_logo}
                alt="logo"
                style={{ height: '60px', objectFit: 'contain' }}
                loading="lazy"
                id="mainLogo"
              />
            </Link>

            {login && role != 'operation' && (
              <div
                className="d-none d-sm-flex flex-column align-items-center fs-7 justify-content-center"
                ref={ref}
              >
                <Link className="nav-list green-txt fw-bold" to="/">
                  {t('cases')}
                </Link>
                <hr className="m-0 mt-1" />
              </div>
            )}
          </div>
          {login && caseDetails && <p className="fw-bold fs-7 text-center">{caseDetails.name}</p>}
          <div className="d-flex align-items-center gap-2 gap-sm-3">
            <div className="d-none d-sm-block">
              <ThemeToggle />
            </div>
            {login ? (
              <div className="d-none d-sm-flex  align-items-center fs-7">
                {/* <img src={notify} className="me-sm-4" /> */}
                <DropDown
                  txt={userName?.match(/\b(\w)/g)?.join('') || ''}
                  className="text-uppercase border-0 primary-txt bg-transparent p-0"
                  dropClassName="bg-green rounded-5 theme-toggle"
                  option={role == 'patient' ? dropDownOptions : dropDownOptions.slice(1)}
                  type="profile"
                />
                <p className="text-capitalize d-none ms-2 d-sm-block primary-txt">
                  {role == 'expert' && 'dr.'} {userName}
                </p>
              </div>
            ) : (
              role && (
                <div className="d-flex">
                  {!pathname.includes('login') && (
                    <MainBtn
                      txt={t('onboarding.login')}
                      style="green-btn me-2 align-self-start"
                      action={() => navigate('/')}
                      key="navLoginBtn"
                    />
                  )}
                </div>
              )
            )}
            <div className="d-none d-sm-block">
              <LanguageDropdown />
            </div>
            <div className="d-sm-none" id="sidebar-toggle" onClick={toggleSidebar}>
              <img
                src={close}
                className={
                  'position-absolute icon-color animate__animated ' +
                  (showSideBar ? 'animate__rotateIn' : 'animate__rotateOut')
                }
                width={40}
                key="close-icon"
              />
              <img
                src={breadcrumb}
                className={
                  'position-absolute icon-color animate__animated ' +
                  (showSideBar ? 'animate__rotateOut' : 'animate__rotateIn')
                }
                width={25}
                key="breadcrumb-icon"
              />
            </div>
            <p className="green-txt fw-bold fs-6 border-start border-3 px-1 px-sm-3 header-second-opinion text-capitalize">
              {t('navbar.second_opinion.0')}
              <br />
              {t('navbar.second_opinion.1')}
            </p>
          </div>
        </div>
      </nav>
      <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} options={dropDownOptions} />
    </>
  );
}

export default NavBar;
