import PopupContainer from '../../Popup_Container.jsx';

function ErrorGuidesPop({ open, setOpenPop, steps }) {
  return (
    <PopupContainer title="Error Guides" open={open} onClose={() => setOpenPop(false)}>
      <div>
        <ol className="ms-3 fs-7 fw-medium p-0">
          {steps?.map((e) => (
            <li>{e}</li>
          ))}
        </ol>
      </div>
    </PopupContainer>
  );
}
export default ErrorGuidesPop;
