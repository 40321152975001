import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as CookieConsent from 'vanilla-cookieconsent';

function Footer() {
  const { role } = useSelector((state) => state.mainFun.user);
  const { t } = useTranslation();

  return (
    <footer className="footer d-flex jusitfy-content-center py-3">
      <p className="text-capitalize">
        {t('powered_by')}{' '}
        <a href="https://plusone.expert" target="_blank" className="green-txt text-decoration-underline">
          PlusOne health
        </a>{' '}
        GmbH
      </p>
      {role && (
        <div className="green-text d-flex">
          <Link to={`${role}/termsofuse`} className="green-txt text-capitalize">
            {t('terms_use')}
          </Link>
          <Link to={`${role}/privacypolicy`} className="green-txt ms-2 ms-sm-5 text-capitalize">
            {t('privacy_policy')}
          </Link>
          {localStorage.token && (
            <p onClick={() => CookieConsent.showPreferences()} className="green-txt ms-2 ms-sm-5 pointer">
              {t('cookies_pref')}
            </p>
          )}
        </div>
      )}
    </footer>
  );
}

export default Footer;
