import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useFetch from '../../../hooks/useFetch.js';
import { refetchCaseDetails } from '../../../features/case_details.js';
import PopupContainer from '../../Popup_Container.jsx';

const TransferMDTLeadPop = ({ trigger, exId }) => {
  const { t } = useTranslation();
  const caseDetails = useSelector((state) => state.caseDetails.details);
  const { fetchData } = useFetch({});
  const dispatch = useDispatch();

  const transferLeadership = async (_, close) => {
    const params = { case_id: caseDetails.id, expert_id: exId?.expert_id };
    await fetchData({ url: 'expert/assign', loading: true, body: { params } }).then((result) => {
      if (result) {
        dispatch(refetchCaseDetails());
        close();
      }
    });
  };

  const btns = [
    {
      txt: t('case_view.btns.accept'),
      style: 'green-btn fw-semibold',
      type: 'submit'
    },
    {
      txt: t('cancel'),
      style: 'grey-btn fw-semibold',
      type: 'button',
      action: (e, close) => close()
    }
  ];

  return (
    <PopupContainer
      title={t('case_view.btns.transfer_leadership')}
      btns={btns}
      trigger={trigger}
      handleSubmit={transferLeadership}
    >
      <p>
        Are you sure you want to transfer the leadership to <u className="fw-bold green-txt">Dr.{exId?.name}</u> ?
      </p>
    </PopupContainer>
  );
};

export default TransferMDTLeadPop;
