import { createSlice } from '@reduxjs/toolkit';
const { VITE_PORTAL_ROLE: userRole } = import.meta.env;

export const mainFunSlice = createSlice({
  name: 'mainFun',
  initialState: {
    loading: [],
    alert: '',
    user: {
      role: userRole == 'both' ? '' : userRole,
      firstName: '',
      lastName: '',
      email: '',
      name: '',
      id: ''
    },
    entityDetails: {}
  },
  reducers: {
    setLoading: (state, action) => {
      action.payload ? state.loading.push(1) : state.loading.pop();
    },
    setAlert: (state, action) => {
      state.alert = action.payload;
    },
    setUserDetails: (state, action) => {
      console.log(action.payload);
      action.payload.user_type && (state.user.role = action.payload.user_type);
      action.payload.first_name && (state.user.firstName = action.payload.first_name);
      action.payload.last_name && (state.user.lastName = action.payload.last_name);
      action.payload.email && (state.user.email = action.payload.email);
      action.payload.name && (state.user.name = action.payload.name);
      action.payload.id && (state.user.id = action.payload.id);
      if (action.payload.user_type == 'operation') {
        state.user.finance_report_allowed = action.payload.finance_report_allowed;
        state.user.medical_report_allowed = action.payload.medical_report_allowed;
      }
    },
    setEntityDetails: (state, action) => {
      state.entityDetails = action.payload;
    }
  }
});

export const { setLoading, setAlert, setUserDetails, setEntityDetails } = mainFunSlice.actions;

export default mainFunSlice.reducer;
