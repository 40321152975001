import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Register from './register.jsx';
import CaseList from '../../pages/common/case_list.jsx';
import Login from '../common/login.jsx';
import ChangePassword from '../common/change_password.jsx';
import ForgetPassword from '../common/forgot_password';
import ResetPassword from '../common/reset_passowrd.jsx';
import { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TermsOfUse from '../common/terms_of_use.jsx';
import PrivacyPolicy from '../common/privacy_policy.jsx';
import PrivacyPoilcyPop from '../../components/popups/expertActionBars/Privacy_Poilcy_Pop.jsx';
import { setUserDetails } from '../../features/main_functions.js';
const CaseView = lazy(() => import('./case_view.jsx'));

function ExpertRoutes() {

  const role = useSelector((state) => state.mainFun.user.role);
  const portalToken = import.meta.env.VITE_EXPERT_PORTAL;
  const [queuedUrl, setQueuedUrl] = useState();
  const [privacy, setPrivacy] = useState(false);

  const { pathname } = useLocation();
  const dispatch = useDispatch();

  //check's if there is token then user is logged in then he can acces this url, else he is navigated to login page
  const HandleRoutes = ({ element }) => {
    if (!localStorage.token) {
      setQueuedUrl(pathname);
      return <Navigate to="/expert/login" />;
    } else if (localStorage.token && role == 'expert' && privacy && element) {
      if (queuedUrl) {
        setQueuedUrl();
        return <Navigate to={queuedUrl} />;
      } else {
        return element;
      }
    } else if (localStorage.token && !element) {
      return <Navigate to="/expert/home" />;
    } else if (role != 'expert') {
      return <Navigate to={`/${role}/home`} />;
    } else {
      return null;
    }
  };

  const HandleBourdingRoutes = ({ element }) => (localStorage.token ? <Navigate to="../home" /> : element);

  useEffect(() => {
    if (role != 'expert' && !localStorage.token) {
      dispatch(setUserDetails({ user_type: 'expert' }));
    }
  }, []);

  return (
    <>
      <PrivacyPoilcyPop setPrivacy={setPrivacy} privacy={privacy} />
      <Routes>
        <Route exact path="/" element={<HandleRoutes />} />
        <Route
          exact
          path="/signup"
          element={<HandleBourdingRoutes element={<Register portalToken={portalToken} />} />}
        />
        <Route
          exact
          path="/login"
          element={<HandleBourdingRoutes element={<Login portalToken={portalToken} role="expert" />} />}
        />
        <Route
          exact
          path="/forgotPassword"
          element={<HandleBourdingRoutes element={<ForgetPassword portalToken={portalToken} />} />}
        />
        <Route exact path="/password/reset" element={<HandleBourdingRoutes element={<ResetPassword />} />} />
        <Route exact path="/changePassword" element={<HandleRoutes element={<ChangePassword />} />} />
        <Route exact path="/home" element={<HandleRoutes element={<CaseList role="expert" />} />} />
        <Route
          exact
          path="/case/:caseId"
          element={
            <HandleRoutes
              element={
                <Suspense>
                  <CaseView key="caseView" />
                </Suspense>
              }
            />
          }
        />
        <Route
          exact
          path="/case/view/:caseId"
          element={
            <Suspense>
              <CaseView />
            </Suspense>
          }
        />
        <Route exact path="/privacyPolicy" element={<PrivacyPolicy portalToken={portalToken} />} />
        <Route exact path="/termsOfUse" element={<TermsOfUse portalToken={portalToken} />} />
        <Route exact path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
}

export default ExpertRoutes;
