import useFetch from '../../hooks/useFetch.js';
import { useTranslation } from 'react-i18next';
import 'quill/dist/quill.snow.css';

function PrivacyPolicy({ portalToken }) {
  const { t } = useTranslation();

  const { data } = useFetch({
    url: 'website/privacy',
    body: { params: { 'Portal-Token': portalToken } },
    loading: true
  });

  const htmlDecode = (input) => {
    let doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <p className="fs-3 fw-bold mb-5">{t('privacy_policy')}</p>
      <div id="editor" className="ql-editor w-100">
        <div
          dangerouslySetInnerHTML={{
            __html: htmlDecode(data?.result?.content) || '<p>No text available</p>'
          }}
        />
      </div>
    </div>
  );
}

export default PrivacyPolicy;
