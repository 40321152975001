import { useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useFetch from '../../hooks/useFetch.js';
import moment from 'moment';

const VcBar = () => {
  const [token, setToken] = useState(false);
  const { data } = useFetch({ url: 'vc/user/scheduled/slots/get', loading: false, body: {} });

  useLayoutEffect(() => {
    if (data?.result) {
      if (data.result.vc_cases.length > 0) {
        let token = '';
        data.result.vc_cases.forEach((e) => e.vc_token && (token = e.vc_token));
        setToken(token);
      }
    }
  }, [data]);

  return (
    token && (
      <div className="video-call-bar bg-danger">
        <p className="text-center text-white">
          you have an ongoing video call{' '}
          <Link to={'/vc/' + token} className="text-primary text-decoration-underline fw-bold">
            join now
          </Link>
        </p>
      </div>
    )
  );
};

export default VcBar;
