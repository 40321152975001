import Form from '../../components/Form.jsx';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../features/main_functions.js';
import useFetch from '../../hooks/useFetch.js';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

function ChangePassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { fetchData } = useFetch({});
  const { t } = useTranslation();

  const sendChangePasswordData = useCallback(async (data) => {
    console.log(data);
    await fetchData({
      url: 'password/update',
      loading: true,
      body: {
        params: {
          current_password: data['old password'],
          new_password: data['password']
        }
      }
    }).then((result) => {
      if (result) {
        dispatch(setAlert({ txt: t('alert_box.password_changed'), type: 'success' }));
        navigate('../home');
      }
    });
  }, []);

  let inputArr = [
    {
      title: t('onboarding.old_password'),
      placeholder: t('enter_your', { val: 'passowrd' }),
      type: 'password',
      name: 'old password'
    },
    {
      title: t('onboarding.password'),
      placeholder: t('enter_your', { val: 'passowrd' }),
      type: 'password',
      name: 'password'
    },
    {
      title: t('onboarding.confirm_password'),
      placeholder: t('enter_your', { val: 'passowrd' }),
      type: 'password',
      name: 'confirm password'
    }
  ];

  let btnArr = [{ title: t('save'), style: 'green-btn', type: 'submit' }];

  return (
    <div>
      <Form
        title={t('onboarding.change_password')}
        inputArr={inputArr}
        btnArr={btnArr}
        smallForm={true}
        type="change password"
        onSubmit={sendChangePasswordData}
        key="changePassword"
      />
    </div>
  );
}
export default ChangePassword;
