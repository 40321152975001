import { useNavigate } from 'react-router-dom';
import Form from '../../components/Form.jsx';
import { useState, useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom/dist/index.js';
import useFetch from '../../hooks/useFetch.js';
import { useTranslation } from 'react-i18next';

function Register() {
  const [userDetails, setUserDetails] = useState();
  const [userConfirmed, setUserConfirmed] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');

  const navigate = useNavigate();
  const { fetchData } = useFetch({});
  const { t } = useTranslation();


  let inputArr = [
    {
      title: t('onboarding.email'),
      placeholder: t('enter_your', { val: 'onboarding.password' }),
      disabled: true,
      name: 'email'
    },
    {
      title: t('demographics.name'),
      placeholder: t('enter_your', { val: 'demographics.name' }),
      disabled: true,
      name: 'name'
    },
    {
      title: t('onboarding.password'),
      placeholder: t('enter_your', { val: 'onboarding.password' }),
      type: 'password',
      name: 'password'
    },
    {
      title: t('onboarding.confirm_password'),
      placeholder: t('enter_your', { val: 'onboarding.password' }),
      type: 'password',
      name: 'confirm password'
    }
  ];

  let btnArr = [
    {
      title: t('onboarding.create_account'),
      style: 'green-btn',
      type: 'submit'
    }
  ];

  const checkToken = async () => {
    await fetchData({
      url: 'expert/check/register',
      loading: true,
      body: { params: { registration_token: token } }
    }).then((result) => {
      if (result) {
        setUserDetails(result.result);
        setUserConfirmed(true);
      } else {
        navigate('../login');
      }
    });
  };

  useEffect(() => {
    checkToken();
  }, []);

  const sendRegisterData = useCallback(async (data) => {
    await fetchData({
      url: 'expert/set/password',
      loading: true,
      body: {
        params: {
          password: data.password,
          registration_token: token
        }
      }
    }).then((result) => {
      if (result) {
        localStorage.token = result.result?.plusone_api_key;
        localStorage.name = result.result?.name;
        navigate('/');
      }
    });
  }, []);

  return (
    userConfirmed && (
      <div>
        <Form
          title={t('onboarding.create_account')}
          inputArr={inputArr}
          btnArr={btnArr}
          type="register"
          onSubmit={sendRegisterData}
          defaultValues={userDetails}
          smallForm={true}
          key="register"
        />
      </div>
    )
  );
}
export default Register;
