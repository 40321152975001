import { useTranslation } from 'react-i18next';
import search from '../assets/images/svg/search.svg';
import { useEffect, useRef } from 'react';

const SearchInput = ({ onChange }) => {
  const { t } = useTranslation();
  const timeOutRef = useRef(null);

  useEffect(() => {
    return () => clearTimeout(timeOutRef.current);
  }, []);

  return (
    <div className="input-group">
      <span className="input-group-text bg-white border-end-0 search">
        <img src={search} className="icon-color" alt="search" />
      </span>
      <input
        type="text"
        className="form-control border-start-0 search ps-0"
        placeholder={t('case_list.search')}
        onChange={(e) => {
          clearTimeout(timeOutRef.current);
          timeOutRef.current = setTimeout(() => {
            onChange(e.target.value);
          }, 800);
        }}
      />
    </div>
  );
};

export default SearchInput;
