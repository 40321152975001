import clsx from 'clsx';

const Table = ({ tHead, data = [], stickLastCol = true }) => {
  console.log("🚀 ~ Table ~ data:", data)
  return (
    <div className="case-table mt-4">
      <table className={`table table-striped ${stickLastCol ? 'stick-last-col' : ''} m-0`}>
        <thead>
          <tr className="text-capitalize">
            {tHead?.map((e) => (
              <th scope="col" key={e}>
                {e}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((row, i) => (
            <tr className="text-capitalize" key={'tr' + row + i}>
              {row.map((cell, j) => (
                <td
                  className={clsx(cell.className, 'py-3')}
                  style={{ whiteSpace: 'pre-line' }}
                  key={'tr' + i + 'td' + j}
                >
                  {cell.label || '----'}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
