import clsx from 'clsx';
import { Fragment } from 'react';

function DropDown({ txt, className, dropClassName, option, img, type, imgFilter = true, autoClose = true }) {
  return (
    <div className={'dropdown ' + dropClassName}>
      <button
        className={className}
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-bs-auto-close={autoClose}
        data-disable-animation={true}
      >
        {img && <img src={img} width={20} className={imgFilter ? 'icon-color' : ''} />}
        {txt}
      </button>
      <ul
        className={`dropdown-menu ${
          type == 'filter' ? '' : 'profile-dropdown'
        } p-2 animate__animated animate__fadeIn`}
      >
        {type == 'filter' ? (
          <>
            {option.map((e, i) => (
              <Fragment key={'listdrop' + i}>
                {e.type == 'date' ? (
                  <li>
                    <input type="date" className="mainInput" onChange={e.action} />
                  </li>
                ) : e.type == 'reset' ? (
                  <>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <button
                        className="dropdown-item bg-primary text-light text-center rounded-3"
                        onClick={e.action}
                      >
                        {e.txt}
                      </button>
                    </li>
                  </>
                ) : (
                  <li className="btn group dropstart p-0 w-100 text-start">
                    <button className="dropdown-item px-0 py-2 primary-txt">&laquo; {e.txt}</button>
                    <ul className="dropdown-menu dropdown-submenu p-2">
                      {e.options.map((o) => (
                        <li key={o + i}>
                          <button
                            className={clsx('dropdown-item primary-txt', {
                              'bg-primary text-light': e.selected?.includes(o.value)
                            })}
                            value={o.value}
                            onClick={e.action}
                          >
                            {o.title}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </li>
                )}
              </Fragment>
            ))}
          </>
        ) : (
          option?.map((e, i) => (
            <li key={e.txt + i} className="d-flex flex-row pointer">
              {e.img && <img width={20} src={e.img} className={imgFilter ? 'icon-color' : ''} />}
              <p className="dropdown-item primary-txt" onClick={e.action}>
                {e.txt}
              </p>
            </li>
          ))
        )}
      </ul>
    </div>
  );
}

export default DropDown;
