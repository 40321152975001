import arrow from '../assets/images/svg/arrow.svg';
import { useEffect, useRef, useState } from 'react';
function ScrollIcon() {
  const [isAtTop, setIsAtTop] = useState(true);
  const [isScrollable, setIsScrollable] = useState(false);

  const observerRef = useRef(null);

  const updateButtonVisibility = () => {
    setIsScrollable(document.body.scrollHeight > window.innerHeight + 24);
  };

  useEffect(() => {
    observerRef.current = new MutationObserver(updateButtonVisibility);
    observerRef.current.observe(document, { subtree: true, childList: true, attributes: true });
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const bodyHeight = document.body.scrollHeight;
      const isAtTop = scrollPosition < (bodyHeight - 800) / 2;
      setIsAtTop(isAtTop);
    };

    if (isScrollable) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrollable]);

  const toggleScroll = () => {
    if (isAtTop) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth'
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  return (
    isScrollable && (
      <div className="circle-arrow-icon scroll-icon pointer" onClick={toggleScroll}>
        <img src={arrow} className='icon-color' style={!isAtTop ? { transform: 'scaleY(-1)' } : {}} />
      </div>
    )
  );
}

export default ScrollIcon;
