import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const TextArea = ({
  title,
  placeholder,
  register,
  errors,
  disabled,
  onBlur,
  onChange,
  value,
  rows = 3,
  control,
  setValue,
  optional,
  name = '',
  required,
  className,
  textRef
}) => {
  const { t } = useTranslation();
  const auto_grow = (element) => {
    element.style.minHeight = '6em';
    element.style.height = element.scrollHeight + 'px';
    element.style.maxHeight = '20em';
  };

  useEffect(() => {
    if (value && setValue) setValue(register?.name, value);
  }, [value]);

  const config = {
    className: `mainInput w-100 form-control ${errors && 'err-active'} ${className ? className : ''}`,
    placeholder: placeholder,
    disabled: disabled,
    rows: rows,
    'test-id': 'input-' + name
  };

  return (
    <div className="d-flex flex-column gap-2">
      {title && (
        <span className="text-capitalize fw-medium">
          {title}
          {optional && <span className="fs-7 grey-txt"> ({t('optional')})</span>}
        </span>
      )}
      {control ? (
        <Controller
          name={register?.name || name}
          control={control}
          defaultValue={value}
          render={({ field }) => (
            <textarea
              {...field}
              onBlur={(e) => {
                field?.onBlur(e);
                onBlur && onBlur(e);
              }}
              onChange={(e) => {
                field?.onChange(e);
                onChange && onChange(e);
                auto_grow(e.target);
              }}
              {...config}
            />
          )}
        />
      ) : (
        <textarea
          name={name}
          required={required}
          onBlur={(e) => {
            onBlur && onBlur(e);
          }}
          onChange={(e) => {
            onChange && onChange(e);
            auto_grow(e.target);
          }}
          ref={textRef}
          defaultValue={value}
          {...config}
        />
      )}

      {errors && <p className="text-capitalize err-msg mt-2 fs-8">{errors}</p>}
    </div>
  );
};

export default TextArea;
