import Footer from '../components/Footer.jsx';
import Loading from '../components/Loading.jsx';
import NavBar from '../components/NavBar.jsx';
import ScrollIcon from '../components/Scroll_Icon.jsx';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import UnderMaintenance from '../pages/common/under_maintenance.jsx';
import VcBar from '../components/VC/Vc_Bar.jsx';
import CookieManager from '../components/cookies/CookieConsent.jsx';
import Alert from '../components/Alert.jsx';
import { Suspense, lazy } from 'react';
import ExpertCookieManager from '../components/cookies/ExpertCookieConsent.jsx';
const PdfGenerator = lazy(() => import('../components/pdf-generator/pdf-generator.jsx'));
const LiveKit = lazy(() => import('../components/VC/live-kit.jsx'));

export const Layout = ({ children, setApiRole, fetchUserRole, apiRole }) => {
  const { pathname } = useLocation();
  const { token } = localStorage;
  const { VITE_PORTAL_ROLE } = import.meta.env;

  const CommonRoute = ({ element }) => {
    if (token && !apiRole && fetchUserRole) {
      fetchUserRole({ redirect: false });
    }
    return element;
  };

  return pathname == '/maintenance' ? (
    <Routes>
      <Route exact path="/maintenance" element={<UnderMaintenance />} />
    </Routes>
  ) : (
    <div className="main">
      <ScrollIcon />
      {VITE_PORTAL_ROLE == 'expert' ? (
        <ExpertCookieManager />
      ) : (
        VITE_PORTAL_ROLE == 'patient' && <CookieManager />
      )}
      {VITE_PORTAL_ROLE !== 'operation' && !pathname.includes('vc') && token && <VcBar />}
      <Loading />
      <Alert />
      <NavBar setApiRole={setApiRole} key="navBar" />
      <Routes>
        {children}
        {VITE_PORTAL_ROLE !== 'operation' && (
          <>
            <Route
              exact
              path="/vc/:token"
              element={
                <CommonRoute
                  element={
                    <Suspense>
                      <LiveKit />
                    </Suspense>
                  }
                />
              }
            />
            <Route
              exact
              path="/case/:caseId/report"
              element={
                <Suspense>
                  <PdfGenerator view={true} />
                </Suspense>
              }
            />
          </>
        )}
        <Route exact path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer key="footer" />
    </div>
  );
};
